import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Box, TableBody, Button, Chip } from '@mui/material';
import { ITaskTable } from './ITaskTable';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function _TaskTable(props: ITaskTable) {
  const navigate = useNavigate();

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell width={200}>Sample ID</TableCell>
              <TableCell>Sample Name</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          {/* Map code */}
          <TableBody>
            {props.data.map((data) => (
              <TableRow key={data._id}>
                <TableCell>{data.sampleId}</TableCell>
                <TableCell>{data.name}</TableCell>
                <TableCell>{moment(data.dueDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  {data.status === 'PENDING' && (
                    <Chip label={'PENDING'} size="small" variant={'outlined'} color={'error'} sx={{ width: '120px' }} />
                  )}
                  {data.status === 'INPROGRESS' && (
                    <Chip
                      label={'IN PROGRESS'}
                      size="small"
                      variant={'outlined'}
                      color={'info'}
                      sx={{ width: '120px' }}
                    />
                  )}
                  {data.status === 'COMPLETED' && (
                    <Chip
                      label={'COMPLETED'}
                      size="small"
                      variant={'outlined'}
                      color={'success'}
                      sx={{ width: '120px' }}
                    />
                  )}
                  {data.status === 'REJECT' && (
                    <Chip
                      label={'REJECT'}
                      size="small"
                      variant={'outlined'}
                      color={'warning'}
                      sx={{ width: '120px' }}
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="text"
                    color="info"
                    onClick={() => navigate(`/laboratory/samples/result?id=${data._id}`)}
                  >
                    ↗ Parameter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const TaskTable = connect(mapStateToProps, mapDispatchToProps)(_TaskTable);
