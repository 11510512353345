import _ from 'lodash';
import React from 'react';

export interface IGeneralTableProps {
  fields: {
    characteristic: string;
    results: string;
    requirements: string;
    permissibleLimit: string;
    method: string;
    unit: string;
    group: string;
  }[];
}

export default function GeneralTable2(props: IGeneralTableProps) {
  const fieldsWithGroups = _.groupBy(props.fields, 'group');

  return (
    <>
      {Object.keys(fieldsWithGroups).map((group, gidx) => {
        const fields = fieldsWithGroups[group];

        return (
          <div className="table-with-border" style={{ width: '100%', marginTop: 20 }}>
            <table style={{ width: '100%' }}>
              <thead>
                {group !== 'undefined' && group ? (
                  <tr>
                    <th colSpan={6} style={{ textAlign: 'left' }}>
                      {group === 'undefined' ? 'N/A' : group}
                    </th>
                  </tr>
                ) : null}

                <tr>
                  <th style={{ width: '10mm' }}>Sr.No.</th>
                  <th>Characteristic</th>
                  <th style={{ width: '15mm' }}>Unit</th>
                  <th style={{ width: '30mm' }}>Results</th>
                  <th style={{ width: '30mm' }}> Max Requirement (Acceptable Limit)</th>
                  <th style={{ width: '55mm' }}>Method of Test, Ref. IS or As per SOP No</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{field.characteristic}</td>
                      <td>{field.unit || '-'}</td>
                      <td>{field.results}</td>
                      <td>{field.requirements}</td>
                      <td>{field.method}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}
