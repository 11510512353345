export const editorTokens = [
  { title: 'Logo', slug: 'logo', id: 'logo' },
  { title: 'Sample ID QR Code', slug: 'sampleIdQRCode', id: 'sampleIdQRCode' },
  { title: 'Result Parameter Table 5 col', slug: 'resultParameterTable', id: 'resultParameterTable' },
  { title: 'Result Parameter Table 4 col', slug: 'resultParameterTableTwo', id: 'resultParameterTableTwo' },
  {
    title: 'Result Parameter Table (Pharma)',
    slug: 'resultparameterpharmatabletwo',
    id: 'resultparameterpharmatabletwo',
  },
  { title: 'Sample ID', slug: 'sampleId', id: 'sampleId' },
  { title: 'Customer Name', slug: 'customerName', id: 'customerName' },
  { title: 'Customer Address', slug: 'customerAddress', id: 'customerAddress' },
  { title: 'Due Date', slug: 'dueDate', id: 'dueDate' },
  { title: 'Lab Due Date', slug: 'labDueDate', id: 'labDueDate' },
  { title: 'Collection Date', slug: 'collectionDate', id: 'collectionDate' },
  { title: 'Manufacturing Date', slug: 'mfgDate', id: 'mfgDate' },
  { title: 'Exp Date', slug: 'expDate', id: 'expDate' },
  { title: 'Exp Date (MM/YYYY)', slug: 'expDateMMYY', id: 'expDateMMYY' },
  { title: 'Mfg Date (MM/YYYY)', slug: 'mfgDateMMYY', id: 'mfgDateMMYY' },
  { title: 'Sample Name', slug: 'name', id: 'name' },
  { title: 'Generic Name', slug: 'genericName', id: 'genericName' },
  { title: 'Sample Code', slug: 'sampleCode', id: 'sampleCode' },
  { title: 'Brand Name', slug: 'brandName', id: 'brandName' },
  { title: 'Manufacture', slug: 'manufacture', id: 'manufacture' },
  { title: 'Marking', slug: 'marking', id: 'marking' },
  { title: 'Supplier/Importer', slug: 'supplier', id: 'supplier' },
  { title: 'Batch/Lot No', slug: 'batchNo', id: 'batchNo' },
  { title: 'Batch Size', slug: 'batchSize', id: 'batchSize' },
  { title: 'Drug Lic No', slug: 'drugLicNo', id: 'drugLicNo' },
  { title: 'Type', slug: 'type', id: 'type' },
  { title: 'Description', slug: 'description', id: 'description' },
  { title: 'Sample Packing', slug: 'samplePacking', id: 'samplePacking' },
  { title: 'Sample Qty', slug: 'sampleQty', id: 'sampleQty' },
  { title: 'Sample Condition', slug: 'sampleCondition', id: 'sampleCondition' },
  { title: 'Environment Condition', slug: 'envCondition', id: 'envCondition' },
  { title: 'Serving Size', slug: 'servingSize', id: 'servingSize' },
  { title: 'Sampling Method', slug: 'samplingMethod', id: 'samplingMethod' },
  { title: 'HOD', slug: 'hod', id: 'hod' },
  { title: 'Collection By', slug: 'collectionBy', id: 'collectionBy' },
  { title: 'Analysis Date', slug: 'analysisDate', id: 'analysisDate' },
  { title: 'Complete Date', slug: 'completeDate', id: 'completeDate' },
  { title: 'Label', slug: 'label', id: 'label' },
  { title: 'Sample Received Mode', slug: 'sampleReceivedMode', id: 'sampleReceivedMode' },
  { title: 'Other Information', slug: 'otherInformation', id: 'otherInformation' },
  { title: 'Declaration regarding Food Additives/if Any other', slug: 'otherInformation', id: 'otherInformation' },
  { title: 'reportDate', slug: 'reportDate', id: 'reportDate' },
  { title: 'ULR', slug: 'url', id: 'url' },
  { title: 'Stamp', slug: 'stamp', id: 'stamp' },
  { title: 'Reviewed By', slug: 'reviewedBy', id: 'reviewedBy' },
  { title: 'Authorized Signature 1', slug: 'authorizedSignature1', id: 'authorizedSignature1' },
  { title: 'Authorized Signature 2', slug: 'authorizedSignature2', id: 'authorizedSignature2' },
  { title: 'Ref. No / W.D. No.', slug: 'refNo', id: 'refNo' },
  { title: 'Sample Location', slug: 'sampleLocation', id: 'sampleLocation' },
  { title: 'Exp date for food sample', slug: 'expDateForFoodSample', id: 'expDateForFoodSample' },
  { title: 'Location of Analysis', slug: 'locationOfAnalysis', id: 'locationOfAnalysis' },
  { title: 'Source of Water', slug: 'sourceOfWater', id: 'sourceOfWater' },
  { title: 'Statement', slug: 'statement', id: 'statement' },
  { title: 'B/E No', slug: 'beNo', id: 'beNo' },
  { title: 'Seal on container', slug: 'sealOnContainer', id: 'sealOnContainer' },
  { title: 'Date of Dispatch/Sealing/Drawl', slug: 'dateOfDispatch', id: 'dateOfDispatch' },
  { title: 'Test Memo no & Date', slug: 'testMemoAndDate', id: 'testMemoAndDate' },
  { title: 'Regulation No', slug: 'regulationNo', id: 'regulationNo' },
  { title: 'Sample Description', slug: 'sampleDescription', id: 'sampleDescription' },
  { title: 'Physical Appearance', slug: 'physicalAppearance', id: 'physicalAppearance' },
  { title: 'FASSI logo and License No', slug: 'fassiLogoAndLicenseNo', id: 'fassiLogoAndLicenseNo' },
  { title: 'List of ingrediation', slug: 'listOfIngrediation', id: 'listOfIngrediation' },
  { title: 'Nurtritional Information', slug: 'nutritionalInformation', id: 'nutritionalInformation' },
  { title: 'Declaration regarding Veg or Non-Veg and Symbol', slug: 'vagNonVegDetails', id: 'vagNonVegDetails' },
  { title: 'Name and complete address of importer', slug: 'nameAndAddressOfImporter', id: 'nameAndAddressOfImporter' },
  { title: 'Country of origin for imported food', slug: 'countryOfOrigin', id: 'countryOfOrigin' },
  { title: 'Instruction for used', slug: 'instructionForUsed', id: 'instructionForUsed' },
  { title: 'Test Method', slug: 'testMethod', id: 'testMethod' },
  { title: 'Name of plant/section', slug: 'nameOfPlantSection', id: 'nameOfPlantSection' },
  { title: 'Condition of Package', slug: 'purposeOfMonitoring', id: 'purposeOfMonitoring' },
  { title: 'Emission Source Monitor', slug: 'emissionSourceMonitor', id: 'emissionSourceMonitor' },
  {
    title: 'Sampling Time',
    slug: 'productManufacturedSamplingTime',
    id: 'productManufacturedSamplingTime',
  },
  { title: 'Name of authorized person', slug: 'nameOfAuthorizedPerson', id: 'nameOfAuthorizedPerson' },
  { title: 'Physical Appearance of  sample', slug: 'controlMeasures', id: 'controlMeasures' },
  { title: 'Authorized Address', slug: 'authorizedPersonAddress', id: 'authorizedPersonAddress' },
  { title: 'Labelling-Details Sufficient', slug: 'recoveryOfMaterial', id: 'recoveryOfMaterial' },
  { title: 'Seal on container/BIS Seal', slug: 'sealOnContainerBISSeal', id: 'sealOnContainerBISSeal' },
  {
    title: 'If condition of Packing or Sample or details provided in the label is not acceptable,provide reason',
    slug: 'generalSensory',
    id: 'generalSensory',
  },
  { title: 'CHA Regn. No', slug: 'CHSRegnNo', id: 'CHSRegnNo' },
  { title: 'Fugitive Emission (if any)', slug: 'fugitiveEmission', id: 'fugitiveEmission' },
  { title: 'CHA G/H Card Holder', slug: 'CHACardHolder', id: 'CHACardHolder' },
  { title: 'Stack Temperature', slug: 'stackTemp', id: 'stackTemp' },
  {
    title: 'Date and place of collection of sample',
    slug: 'dateAndPlaceOfCollectionOfSample',
    id: 'dateAndPlaceOfCollectionOfSample',
  },
  { title: 'Ambient Temperature', slug: 'ambientTemperature', id: 'ambientTemperature' },
  { title: 'SR. No', slug: 'srNo', id: 'srNo' },
  { title: 'Stack Velocity/IO Signature', slug: 'stackVelocity', id: 'stackVelocity' },
  { title: 'Seals fixed NO', slug: 'sealsFixedNo', id: 'sealsFixedNo' },
  { title: 'Quantity of emission shoe always Conforms', slug: 'conformsOrNon', id: 'conformsOrNon' },
  { title: 'Sample Received From', slug: 'sealedCoverByThe', id: 'sealedCoverByThe' },
  { title: 'Service mode', slug: 'serviceMode', id: 'serviceMode' },
  { title: 'Fee Received', slug: 'foundTheSampleToBe', id: 'foundTheSampleToBe' },
  { title: 'Sample Status', slug: 'sampleStatus', id: 'sampleStatus' },
  { title: 'Type of Packing', slug: 'parametersMonitors', id: 'parametersMonitors' },
  { title: 'Status Reasons', slug: 'statusReasons', id: 'statusReasons' },
  { title: 'Status Reasons', slug: 'statusReasons', id: 'statusReasons' },
  { title: 'Opinion', slug: 'opinion', id: 'opinion' },
];

/*

*/
