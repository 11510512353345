import {
  getLaboratoryList,
  getLaboratoryById,
  createLaboratory,
  searchMasterList,
  createMaster,
  getMasterById,
  updateMaster,
  deleteMasterById,
  searchTestGroup,
  createTestGroup,
  deleteTestGroup,
  updateTestGroup,
  getTestGroupById,
  createSample,
  searchSample,
  getSampleById,
  assignSample,
  addResult,
  approveResult,
  rejectResult,
  createJobAllotment,
  createTestRequest,
  createJobSheet,
  getReferenceId,
  updateReference,
  createReference,
  getReference,
  searchReference,
  deleteReferenceById,
  generateURLNumber,
  getDepartment,
  getDepartmentById,
  updateDepartment,
  createDepartment,
  deleteDepartmentById,
  updateSample,
  getMyTasks,
  generateResult,
  multiAssign,
  createResultTemplate,
  deleteResultTemplate,
  getResultTemplate,
  getResultTemplateById,
  updateResultTemplate,
  searchResultTemplate,
  searchDepartment,
  updateResult,
  getPendingInvoiceSamples,
  generateInvoice,
  getCustomerInvoice,
  getInvoiceById,
  updateCutomerAmount,
  getPriceList,
  createPriceList,
  getPriceListById,
  getParametersList,
  updatePriceList,
  deletePriceList,
  getPriceListByMasterAndCustomer,
  assignMultipleUser,
} from './LaboratoryService';
export type { IGetLaboratoryById, IAssignSampleData, IAddResultData, IMultiAssign } from './ILaboratoryService';

export const LaboratoryService = {
  getLaboratoryList,
  getLaboratoryById,
  createLaboratory,
  searchMasterList,
  createMaster,
  getMasterById,
  updateMaster,
  deleteMasterById,
  searchTestGroup,
  createTestGroup,
  deleteTestGroup,
  updateTestGroup,
  getTestGroupById,
  createSample,
  searchSample,
  getSampleById,
  assignSample,
  addResult,
  approveResult,
  rejectResult,
  updateResult,
  createJobAllotment,
  createTestRequest,
  createJobSheet,
  createReference,
  updateReference,
  getReferenceId,
  getReference,
  searchReference,
  deleteReferenceById,
  generateURLNumber,
  getDepartment,
  getDepartmentById,
  updateDepartment,
  createDepartment,
  deleteDepartmentById,
  updateSample,
  getMyTasks,
  generateResult,
  multiAssign,
  assignMultipleUser,
  createResultTemplate,
  deleteResultTemplate,
  getResultTemplate,
  getResultTemplateById,
  updateResultTemplate,
  searchResultTemplate,
  searchDepartment,
  getPendingInvoiceSamples,
  generateInvoice,
  getCustomerInvoice,
  getInvoiceById,
  updateCutomerAmount,
  getPriceList,
  createPriceList,
  deletePriceList,
  getPriceListById,
  updatePriceList,
  getPriceListByMasterAndCustomer,
  getParametersList,
};
