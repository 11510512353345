import actionTypes from 'constants/ActionTypes';
import { IDepartment, ILaboratory, IMaster, IReference, IResultTemplate, ISample, ITestGroup } from 'exactt-types/lib';
import { IDispatchType } from 'Reducers/IActionDispatch';
import {
  IGetLaboratoryById,
  LaboratoryService,
  IAssignSampleData,
  IAddResultData,
  IMultiAssign,
} from 'services/LaboratoryService';
import showAlert from './showAlert';
import { IUpdateResult, IUserMultiAssign } from 'services/LaboratoryService/ILaboratoryService';

export const getLaboratoryList = (): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getLaboratoryList();

      dispatch({
        type: actionTypes.laboratory.GET_LABORATORYS,
        payload: {
          list: res.data.data,
        },
      });

      return res;
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch({
          type: actionTypes.auth.LOGOUT,
          payload: null,
        });
        showAlert(dispatch, error.response.data.message ?? 'Session expired', 'error');
      } else {
        showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      }
      return error;
    }
  };
};

export const getLaboratoryById = (request: IGetLaboratoryById): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getLaboratoryById(request);

      dispatch({
        type: actionTypes.laboratory.GET_LABORATORY_BY_ID,
        payload: {
          laboratory: res.data.laboratory,
          access: res.data.access,
          departments: res.data.departments,
        },
      });

      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const resetCurrentLaboratory = (): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    dispatch({
      type: actionTypes.laboratory.RESET_CURRENT_LABORATORY,
      payload: null,
    });
  };
};

export const createLaboratory = (request: ILaboratory): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createLaboratory(request);
      showAlert(dispatch, 'Laboratory created!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchMasterList = (laboratoryId: string, params: { page: number; size: number; query: string }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.searchMasterList(laboratoryId, params);
      dispatch({
        type: actionTypes.master.SEARCH_MASTER,
        payload: {
          list: res.data.masters,
          count: res.data.totalPages,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createMaster = (data: IMaster): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createMaster(data);
      dispatch({
        type: actionTypes.master.ADD_MASTER,
        payload: res.data,
      });
      showAlert(dispatch, 'Master created!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updateMaster = (data: IMaster): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.updateMaster(data);
      dispatch({
        type: actionTypes.master.UPDATE_MASTER,
        payload: res.data,
      });
      showAlert(dispatch, 'Master updated!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getMasterById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getMasterById(laboratoryId, _id);
      dispatch({
        type: actionTypes.master.GET_MASTER_BY_ID,
        payload: res.data,
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const deleteMasterById = (
  laboratoryId: string,
  _id: string,
  params: { page: number; size: number; query: string }
): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      await LaboratoryService.deleteMasterById(laboratoryId, _id);
      let res = await LaboratoryService.searchMasterList(laboratoryId, params);
      dispatch({
        type: actionTypes.master.DELETE_MASTER_BY_ID,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Master deleted!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchTestGroupList = (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.searchTestGroup(laboratoryId, params);
      dispatch({
        type: actionTypes.testgroup.SEARCH_TESTGROUP,
        payload: {
          list: res.data.testGroups,
          count: res.data.totalPages,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const deleteTestGroup = (
  laboratoryId: string,
  _id: string,
  params: { page: number; size: number; query: string }
): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.deleteTestGroup(laboratoryId, _id);
      let listRes = await LaboratoryService.searchTestGroup(laboratoryId, params);

      dispatch({
        type: actionTypes.testgroup.DELETE_TESTGROUP_BY_ID,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Test group deleted!', 'success');
      return listRes.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createTestGroup = (data: ITestGroup): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createTestGroup(data);
      dispatch({
        type: actionTypes.testgroup.ADD_TESTGROUP,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Test group created!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updateTestGroup = (data: ITestGroup): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.updateTestGroup(data);
      dispatch({
        type: actionTypes.testgroup.EDIT_TESTGROUP,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Test group updated!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getMyTasks = (laboratoryId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getMyTasks(laboratoryId);
      dispatch({
        type: actionTypes.sample.GET_MY_TASKS,
        payload: { list: res.data },
      });
      showAlert(dispatch, 'Task loaded', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getTestGroupById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getTestGroupById(laboratoryId, _id);
      dispatch({
        type: actionTypes.testgroup.GET_TESTGROUP_BY_ID,
        payload: res.data,
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createSample = (data: ISample): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createSample(data);
      dispatch({
        type: actionTypes.sample.ADD_SAMPLE,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Sample created!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updateSample = (data: ISample): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.updateSample(data);
      dispatch({
        type: actionTypes.sample.UPDATE_SAMPLE,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Sample Updated!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchSample = (
  laboratoryId: string,
  params: {
    page?: number;
    size?: number;
    query?: string;
    startDate?: string;
    endDate?: string;
    testGroup?: string;
    customer?: string;
    status?: string;
  }
): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.searchSample(laboratoryId, params);
      dispatch({
        type: actionTypes.sample.SEARCH_SAMPLE,
        payload: {
          list: res.data.samples,
          count: res.data.totalPages,
          reset: params.page === 1,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
export const postMultipleAssign = async(laborateryId:string , data:IUserMultiAssign):Promise<any>=>{
  try {
    let res = await LaboratoryService.assignMultipleUser(laborateryId,data)
    return res
  } catch (error:any) {
    return error
  }
}



export const getSample = (laboratoryId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getMyTasks(laboratoryId);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE,
        payload: {
          list: res.data,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getSampleById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getSampleById(laboratoryId, _id);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE_BY_ID,
        payload: {
          data: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const assignSample = (laboratoryId: string, _id: string, data: IAssignSampleData): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.assignSample(laboratoryId, _id, data);
      dispatch({
        type: actionTypes.sample.ASSIGN_SAMPLE,
        payload: {
          data: res.data,
        },
      });
      showAlert(dispatch, 'Test Assigned!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const assignMultipleSample = (laboratoryId: string, data: IMultiAssign): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.multiAssign(laboratoryId, data);
      dispatch({
        type: actionTypes.sample.ASSIGN_MULTIPLE_SAMPLE,
        payload: {
          data: res.data,
        },
      });

      let _res = await LaboratoryService.getSampleById(laboratoryId, data.sampleId);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE_BY_ID,
        payload: {
          data: _res.data,
        },
      });

      // showAlert(dispatch, 'Test Assigned!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const addResult = (laboratoryId: string, _id: string, sampleId: string, data: IAddResultData): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      await LaboratoryService.addResult(laboratoryId, _id, data);
      // showAlert(dispatch, 'Result added!', 'success');
      let res = await LaboratoryService.getSampleById(laboratoryId, sampleId);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE_BY_ID,
        payload: {
          data: res.data,
        },
      });
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const approveResult = (laboratoryId: string, _id: string, sampleId: string, userId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      await LaboratoryService.approveResult(laboratoryId, _id, userId);
      // showAlert(dispatch, 'Result added!', 'success');
      let res = await LaboratoryService.getSampleById(laboratoryId, sampleId);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE_BY_ID,
        payload: {
          data: res.data,
        },
      });
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const rejectResult = (laboratoryId: string, _id: string, sampleId: string, userId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      await LaboratoryService.rejectResult(laboratoryId, _id, userId);
      // showAlert(dispatch, 'Result added!', 'success');
      let res = await LaboratoryService.getSampleById(laboratoryId, sampleId);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE_BY_ID,
        payload: {
          data: res.data,
        },
      });
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
export const updateResult = (laboratoryId: string, data: IUpdateResult, sampleId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      await LaboratoryService.updateResult(laboratoryId, data, sampleId);
      // showAlert(dispatch, 'Added Details!', 'success');
      let res = await LaboratoryService.getMasterById(laboratoryId, sampleId);
      dispatch({
        type: actionTypes.sample.UPDATE_RESULT,
        payload: {
          data: res.data,
        },
      });
      console.log('resAction -> ', res);
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const generateURLNumber = (laboratoryId: string, sampleId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      await LaboratoryService.generateURLNumber(laboratoryId, sampleId);
      // showAlert(dispatch, 'Result added!', 'success');
      let res = await LaboratoryService.getSampleById(laboratoryId, sampleId);
      dispatch({
        type: actionTypes.sample.GET_SAMPLE_BY_ID,
        payload: {
          data: res.data,
        },
      });
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createJobAllotment = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      const res = await LaboratoryService.createJobAllotment(laboratoryId, _id);
      showAlert(dispatch, 'Test Request Created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createTestRequest = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      const res = await LaboratoryService.createTestRequest(laboratoryId, _id);
      showAlert(dispatch, 'Test Request Created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const generateResult = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      const res = await LaboratoryService.generateResult(laboratoryId, _id);
      showAlert(dispatch, 'Test Request Created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createJobSheet = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      const res = await LaboratoryService.createJobSheet(laboratoryId, _id);
      showAlert(dispatch, 'Test Request Created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createReference = (laboratoryId: string, data: IReference): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      const res = await LaboratoryService.createReference(laboratoryId, data);
      showAlert(dispatch, 'Reference added!', 'success');
      dispatch({
        type: actionTypes.reference.ADD_REFERENCE,
        payload: {
          data: res.data,
        },
      });
      showAlert(dispatch, 'Reference created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getReferenceId = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getReferenceId(laboratoryId, _id);
      dispatch({
        type: actionTypes.reference.GET_REFERENCE_BY_ID,
        payload: {
          data: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updateReference = (laboratoryId: string, data: IReference): any => {
  return async (dispatch: IDispatchType): Promise<IReference> => {
    try {
      let res = await LaboratoryService.updateReference(laboratoryId, data);
      dispatch({
        type: actionTypes.reference.UPDATE_REFERENCE,
        payload: {
          data: res.data,
        },
      });
      showAlert(dispatch, 'Reference updated!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
export const getReference = (laboratoryId: string, params: { page: number; size: number }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getReference(laboratoryId, params);
      dispatch({
        type: actionTypes.reference.GET_REFERENCE,
        payload: {
          list: res.data,
          count: res.data.totalPage,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchReference = (laboratoryId: string, params: { page: number; size: number; query: string }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.searchReference(laboratoryId, params);
      dispatch({
        type: actionTypes.reference.SEARCH_REFERENCE,
        payload: {
          list: res.data.references,
          count: res.data.totalPages,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
export const deleteReferenceById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.deleteReferenceById(laboratoryId, _id);

      let listRes = await LaboratoryService.deleteReferenceById(laboratoryId, _id);
      dispatch({
        type: actionTypes.reference.DELETE_REFERENCE_BY_ID,
        payload: {
          list: listRes.data,
        },
      });
      showAlert(dispatch, 'Reference deleted!', 'success');

      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getDepartment = (laboratoryId: string, params: { page: number; size: number }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getDepartment(laboratoryId, params);
      dispatch({
        type: actionTypes.department.GET_DEPARTMENT,
        payload: {
          list: res.data,
          count: res.data.totalPage,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchDepartment = (laboratoryId: string, params: { page: number; size: number; query: string }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.searchDepartment(laboratoryId, params);
      dispatch({
        type: actionTypes.department.SEARCH_DEPARTMENT,
        payload: {
          list: res.data.departments,
          count: res.data.totalPages,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updateDepartment = (laboratoryId: string, data: IDepartment): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.updateDepartment(laboratoryId, data);
      dispatch({
        type: actionTypes.department.UPDATE_DEPARTMENT,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Department updated!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getDepartmentById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getDepartmentById(laboratoryId, _id);
      dispatch({
        type: actionTypes.department.GET_DEPARTMENT_BY_ID,
        payload: {
          list: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const deleteDepartmentById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.deleteDepartmentById(laboratoryId, _id);
      dispatch({
        type: actionTypes.department.DELETE_DEPARTMENT_BY_ID,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Department deleted!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createDepartment = (laboratoryId: string, data: IDepartment): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createDepartment(laboratoryId, data);
      dispatch({
        type: actionTypes.department.ADD_DEPARTMENT,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Department created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createResultTemplate = (laboratoryId: string, data: IResultTemplate): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createResultTemplate(laboratoryId, data);
      dispatch({
        type: actionTypes.resultTemplate.ADD_RESULT_TEMPLATE,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Result Template created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updateResultTemplate = (laboratoryId: string, data: IResultTemplate): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.updateResultTemplate(laboratoryId, data);
      dispatch({
        type: actionTypes.resultTemplate.UPDATE_RESULT_TEMPLATE,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Result Template updated!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getResultTemplateById = (laboratoryId: string, _id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getResultTemplateById(laboratoryId, _id);
      dispatch({
        type: actionTypes.resultTemplate.GET_RESULT_TEMPLATE_BY_ID,
        payload: {
          list: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getResultTemplate = (laboratoryId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getResultTemplate(laboratoryId);
      dispatch({
        type: actionTypes.resultTemplate.GET_RESULT_TEMPLATE,
        payload: {
          list: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchResultTemplate = (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.searchResultTemplate(laboratoryId, params);
      dispatch({
        type: actionTypes.resultTemplate.SEARCH_RESULT_TEMPLATE,
        payload: {
          list: res.data.resultTemplateList,
          count: res.data.totalPages,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getCustomerPendingSamplesForInvoice = async (laboratoryId: string, customer: string): Promise<any> => {
  try {
    let res = await LaboratoryService.getPendingInvoiceSamples(laboratoryId, customer);
    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const generateInvoiceApi = async (laboratoryId: string, customer: string, data: any): Promise<any> => {
  try {
    let res = await LaboratoryService.generateInvoice(laboratoryId, customer, data);
    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const getCustomerInvoice = async (laboratoryId: string, customer: string): Promise<any> => {
  try {
    let res = await LaboratoryService.getCustomerInvoice(laboratoryId, customer);
    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const getInvoiceByIdAction = async (laboratoryId: string, id: string): Promise<any> => {
  try {
    let res = await LaboratoryService.getInvoiceById(laboratoryId, id);
    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const updateCutomerAmount = async (laboratoryId: string, id: string, data: any): Promise<any> => {
  try {
    let res = await LaboratoryService.updateCutomerAmount(laboratoryId, id, data);
    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const getPriceList = (laboratoryId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getPriceList(laboratoryId);
      dispatch({
        type: actionTypes.priceList.GET_PRICE_LIST,
        payload: {
          list: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const createPriceList = (laboratoryId: string, data: any): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.createPriceList(laboratoryId, data);
      dispatch({
        type: actionTypes.priceList.ADD_PRICE_LIST,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Price List created!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getPriceListById = (laboratoryId: string, id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getPriceListById(laboratoryId, id);
      dispatch({
        type: actionTypes.priceList.GET_PRICE_LIST_BY_ID,
        payload: {
          data: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getParametersList = (laboratoryId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getParametersList(laboratoryId);
      dispatch({
        type: actionTypes.parametersList.GET_ALL_PARAMETERS,
        payload: {
          data: res.data,
        },
      });
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getPriceListByMasterAndCustomer = (laboratoryId: string, customerId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.getPriceListByMasterAndCustomer(laboratoryId, customerId);
      dispatch({
        type: actionTypes.priceList.GET_PRICE_LIST_BY_ID,
        payload: {
          data: res.data.length > 0 ? res.data[0] : null,
        },
      });
      return res.data;
    } catch (error: any) {
      dispatch({
        type: actionTypes.priceList.GET_PRICE_LIST_BY_ID,
        payload: {
          data: null,
        },
      });
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const updatePriceList = (laboratoryId: string, id: string, data: any): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.updatePriceList(laboratoryId, id, data);
      dispatch({
        type: actionTypes.priceList.UPDATE_PRICE_LIST,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Price List updated!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const deletePriceList = (laboratoryId: string, id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await LaboratoryService.deletePriceList(laboratoryId, id);
      dispatch({
        type: actionTypes.priceList.DELETE_PRICE_LIST,
        payload: {
          list: res.data,
        },
      });
      showAlert(dispatch, 'Price List deleted!', 'success');
      return res.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
