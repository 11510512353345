import { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IMultipleSampleProps } from './IMultipleSample';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import { ICustomer, ITestGroup, ISample, IUser, IDepartment } from 'exactt-types/lib';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import AsyncSelect from 'react-select/async';
import { searchTestGroupList } from 'actions/LaboratoryActions';
import { searchCustomer } from 'actions/CustomerActions';
import { TableContainer, Table, TableHead, TableRow, TableCell, Link } from '@mui/material';
import { searchSample, postMultipleAssign } from 'actions/LaboratoryActions';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import useQuery from 'utils/getQueryParams';

function _MultipleSample(props: IMultipleSampleProps) {
  const navigate = useNavigate();
  const laboratoryId = props.laboratory?.details._id || '';
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [multiAssignDepartment, setMultiAssignDepartment] = useState<any>(undefined);
  const [multiAssignUser, setMultiAssignUser] = useState<any>(undefined);
  const [multiAssignAllotmentDate, setMultiAssignAllotmentDate] = useState<any>(moment().toDate());
  const [multiAssignDueDate, setMultiAssignDueDate] = useState<any>(moment().add(3, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedTestGroup, setSelectedTestGroup] = useState<ITestGroup | null>(null);
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [sampleData, setSampleData] = useState<ISample[]>([]);
  const [selectedSampleIds, setSelectedSampleIds] = useState([]);

  const onFocus = async () => {
    let query = useQuery();
    const from = query.get('from') || moment().startOf('month').format('YYYY-MM-DD');
    const to = query.get('to') || moment().format('YYYY-MM-DD');

    setStartDate(from);
    setEndDate(to);
    props.searchSample(props.laboratory?.details?._id || '', {
      startDate: from,
      endDate: to,
    });
  };
  useEffect(() => {
    props.searchTestGroupList(laboratoryId, { page: 1, size: 100, query: '' });
    onFocus();
  }, []);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Assign MultipleSample',
      onClick: () => navigate('/laboratory/MultipleSample'),
    },
  ];

  const testGroupOptions = async (inputValue: string) => {
    const list = await props.searchTestGroupList(laboratoryId, { page: 1, size: 20, query: inputValue });
    return list.data.testGroups.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const getSampleData = (startDate, endDate) => {
    const params = {
      startDate,
      endDate,
      query: '',
    };

    console.log(params, 'params');

    props
      .searchSample(laboratoryId, params)
      .then((res: any) => {
        console.log(res);
        setSampleData(res.data.samples || []);
        const ids = res.data.samples.flatMap((sample) => sample.parameters.map((param) => param.sampleId));
        setSelectedSampleIds(ids);
      })
      .catch((err: any) => {
        console.error('Error fetching sample data:', err);
      });
  };

  useEffect(() => {
    props.searchCustomer(laboratoryId, { page: 1, size: 20, query: customerSearchQuery });
    // eslint-disable-next-line
  }, [customerSearchQuery]);
  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="AssignMultipleSample" />
      <Box
        pt={12}
        ml={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        gap={2}
        width={1000}
      >
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="DD-MM-YYYY"
            value={moment(startDate, 'YYYY-MM-DD').toDate()}
            onChange={(data) => {
              setStartDate(moment(data).format('YYYY-MM-DD'));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD-MM-YYYY"
            value={moment(endDate, 'YYYY-MM-DD').toDate()}
            onChange={(data) => {
              setEndDate(moment(data).format('YYYY-MM-DD'));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <CustomFieldWithLabel width={120} label="Test Group">
          <AsyncSelect
            styles={{
              container: (base) => ({
                ...base,
                width: 200,
              }),
            }}
            loadOptions={testGroupOptions}
            value={{ value: selectedTestGroup?._id, label: selectedTestGroup?.name }}
            defaultOptions={(props.testGroupsList || []).map((i) => ({
              label: i.name,
              value: i._id,
            }))}
            onChange={(newValue: any) => {
              const testGroup = props.testGroupsList?.find((i) => i._id === newValue?.value);
              if (testGroup) setSelectedTestGroup(testGroup);
            }}
          />
        </CustomFieldWithLabel>
        <Button
          variant="contained"
          color="primary"
          onClick={() => getSampleData(startDate, endDate)}
          sx={{ height: '40px', width: '150px', whiteSpace: 'nowrap', textAlign: 'center' }}
        >
          Get Sample
        </Button>
      </Box>
      <Grid container>
        <Grid item pl={3}>
          <FormControl fullWidth size="small" style={{ width: 220 }}>
            <InputLabel id="department-select-label">Department</InputLabel>
            <Select
              fullWidth
              label="Department"
              labelId="department-select-label"
              id="department-select-helper"
              name="department"
              placeholder="Select department"
              value={multiAssignDepartment}
              size="small"
              onChange={(event) => {
                setMultiAssignDepartment(event.target.value);
              }}
            >
              {props.laboratory?.departments?.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item pl={2}>
          <FormControl fullWidth size="small" style={{ width: 220 }}>
            <InputLabel id="user-select-label">User</InputLabel>
            <Select
              label="User"
              fullWidth
              labelId="user-select-label"
              id="user-select-helper"
              name="user"
              placeholder="Select user"
              size="small"
              value={multiAssignUser}
              onChange={(event) => {
                setMultiAssignUser(event.target.value);
              }}
            >
              {props.userList.map((item) => (
                <MenuItem value={item.user._id} key={item._id}>
                  {item.user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item pl={2}>
          <FormControl fullWidth size="small" style={{ width: 220 }}>
            {/* <InputLabel id="department-select-label">Allotment date</InputLabel> */}
            <LocalizationProvider dateAdapter={DateAdapter}>
              <MobileDatePicker
                inputFormat={'DD/MM/YYYY'}
                label="Allotment date"
                onChange={(value) => {
                  setMultiAssignAllotmentDate(moment(value).toDate());
                }}
                value={multiAssignAllotmentDate}
                renderInput={(params) => <TextField {...params} placeholder="Select date" fullWidth margin={'none'} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item pl={2}>
          <FormControl fullWidth size="small" style={{ width: 220 }}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <MobileDatePicker
                inputFormat={'DD/MM/YYYY'}
                label="Due date"
                onChange={(value) => {
                  setMultiAssignDueDate(moment(value).toDate());
                }}
                value={multiAssignDueDate}
                renderInput={(params) => <TextField {...params} placeholder="Select date" fullWidth margin={'none'} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item pl={2}>
          <Box mt={1}>
            <Button
              variant={'contained'}
              onClick={async () => {

                await getSampleData(startDate, endDate);

                await props.postMultipleAssign(laboratoryId, {
                  allotmentDate: multiAssignAllotmentDate,
                  department: multiAssignDepartment,
                  dueDate: multiAssignDueDate,
                  sampleId: selectedSampleIds,
                  user: multiAssignUser,
                });
              }}
            >
              Assign
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* table */}
      <Box mt={3}>
        {' '}
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 500 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Date of Receipt</TableCell>
                <TableCell>Lab ID</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Parameter</TableCell>
                <TableCell>Assign To</TableCell>
                <TableCell>Allotment Date</TableCell>
                <TableCell>Due Date</TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {sampleData.map((sample, index) => {
                return sample.parameters?.map((p, pId) => (
                  <TableRow key={sample.sampleId}>
                    <TableCell>
                      {index + 1}.{pId + 1}
                    </TableCell>
                    <TableCell>{moment(sample.collectionDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{sample.sampleId}</TableCell>
                    <TableCell>{(p.department as unknown as IDepartment)?.name}</TableCell>
                    <TableCell>{p.name}</TableCell>
                    <TableCell>{(p?.assignTo as unknown as IUser)?.name || 'N/A'}</TableCell>
                    <TableCell>{moment(p.allotmentDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{moment(p.dueDate).format('DD/MM/YYYY')}</TableCell>
                  </TableRow>
                ));
              })}
            </tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    testGroupsList: state.laboratory.testGroups,
    searchCustomerList: state.laboratory.searchCustomer,
    sampleList: state.laboratory.samples,
    userList: state.laboratory.users,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        MultipleSample,
        searchTestGroupList,
        searchCustomer,
        searchSample,
        postMultipleAssign,
      },
      dispatch
    ),
  };
}

export const MultipleSample = connect(mapStateToProps, mapDispatchToProps)(_MultipleSample);
