import { Box, MenuItem, Select } from '@mui/material';
import { getSampleById } from 'actions/LaboratoryActions';
// import GeneralPwdHeader from 'PdfPages/components/PwdHeader';

// import GeneralPwdFooter from 'PdfPages/components/PwdFooter';

import A4 from 'PdfPages/pages/A4';
import { useEffect, useState } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { IMaster, ISample, IUser } from 'exactt-types/lib';
import GeneralFields, { GeneralFieldsValue } from 'PdfPages/components/GeneralFields';
import DocumentLatterHead from 'PdfPages/components/DocumentLatterHead';
import moment from 'moment';
import QRCode from 'react-qr-code';

interface SampleResultPrintProps {
  setDisableScroll: (value: boolean) => void;
  getSampleById: (laboratoryId: string, _id: string) => Promise<any>;
  laboratory: ILaboratoryDetails | null;
  sampleDetails: ISample | null;
}

export function _SampleJobSheetPrint(props: SampleResultPrintProps) {
  let query = useQuery();
  let sampleId = query.get('id');

  const [selectedUser, setSelectedUser] = useState('all');

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(props.laboratory?.details._id || '', sampleId);
      setTimeout(() => {
        // window.print();
      }, 1000 * 5);
      // setLoading(false);
    }
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();

    // eslint-disable-next-line
  }, []);

  const values: GeneralFieldsValue[] = [];

  if (props.sampleDetails) {
    values.push({ label: 'Commodity', value: props.sampleDetails.name });
    values.push({ label: 'Lab Code', value: props.sampleDetails.sampleId || '' });
    values.push({
      label: 'Assigned To',
      value:
        props.sampleDetails?.parameters
          ?.filter((f) => {
            // @ts-ignore
            return (f.assignTo as IUser)?._id === selectedUser;
          })
          // @ts-ignore
          ?.map((i) => (i.assignTo as IUser)?.name)[0] || 'All Users',
    });

    values.push({
      label: 'Date of receipt',
      value: moment(props.sampleDetails.collectionDate).format('DD-MM-YYYY') || '',
    });
    values.push({ label: 'Date of Completion', value: '' });
  }

  let users = {};

  props.sampleDetails?.parameters?.map((i, index) => {
    // @ts-ignore
    if ((i.assignTo as IUser)?._id) {
      // @ts-ignore
      users[(i.assignTo as IUser)?._id] = i.assignTo as IUser;
    }
  });

  return (
    <Box>
      {/* Select for users */}
      <Select
        style={{ width: 500 }}
        size="small"
        className="no-print"
        value={selectedUser}
        onChange={(e) => setSelectedUser(e.target.value as string)}
      >
        <MenuItem value={'all'}>All</MenuItem>
        <MenuItem value={'not_assigned'}>Not Assigned</MenuItem>
        {Object.keys(users).map((i) => (
          <MenuItem value={i}>{users[i].name}</MenuItem>
        ))}
      </Select>
      <A4
        header={<DocumentLatterHead laboratory={props.laboratory} />}
        body={
          <>
            <Box mt={-0.5} border={'1px solid #000'} p={2}>
              <Box textAlign={'center'} mb={1} fontWeight={'bold'}>
                JOB SHEET
              </Box>
              <GeneralFields values={values} labelWidth={134} />
              <Box display={'flex'} justifyContent={'flex-end'}>
                <div>
                  <div style={{ height: 'auto', margin: '0 auto', maxWidth: 32, width: '100%' }}>
                    <QRCode
                      size={256}
                      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      value={props.sampleDetails?.sampleId as string}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </Box>
            </Box>
            <Box className="table-with-border">
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ width: '6%' }}>Sr. No</th>
                    <th style={{ width: '30%' }}>Parameters</th>
                    <th style={{ width: '10%' }}>Unit</th>
                    <th style={{ width: '20%' }}>Result</th>
                    <th style={{ width: '10%' }}>Acceptable Limit</th>
                    <th style={{ width: '30%' }}>Method of test to IS</th>
                  </tr>
                </thead>
                <tbody>
                  {props.sampleDetails?.parameters
                    ?.filter((f) => {
                      const findRow = (props.sampleDetails?.master as IMaster)?.parameters?.find(
                        (i) => f.name === i.name
                      );
                      f.method = findRow?.method;
                      if (selectedUser === 'all') {
                        return true;
                      } else if (selectedUser === 'not_assigned') {
                        // @ts-ignore
                        return !(f.assignTo as IUser)?._id;
                      } else {
                        // @ts-ignore
                        return (f.assignTo as IUser)?._id === selectedUser;
                      }
                    })
                    ?.map((i, index) => (
                      <tr>
                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                        <td style={{ textAlign: 'center' }}>{i.name}</td>
                        <td style={{ textAlign: 'center' }}>{i.unit}</td>
                        <td></td>
                        <td>{i.requirements}</td>
                        <td>{i.method}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>
          </>
        }
        footer={
          <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', gap: '10mm', marginTop: 40 }}>
              <div>
                <strong>{selectedUser === 'all' ? 'All ' : (users[selectedUser] as IUser)?.name} </strong>
                <br />
                <strong style={{ fontSize: 12 }}>Analysed By</strong>
              </div>
              <div>
                <strong>{(props?.sampleDetails?.authorizedSignature1 as IUser)?.name}</strong>
                <br />
                <strong style={{ fontSize: 12 }}>Approved By</strong>
              </div>
            </div>
          </>
        }
      />
    </Box>
  );
}
const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleDetails: state.laboratory.sampleDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
      },
      dispatch
    ),
  };
}

export const SampleJobSheetPrint = connect(mapStateToProps, mapDispatchToProps)(_SampleJobSheetPrint);
