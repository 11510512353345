import moment from 'moment';
import * as Yup from 'yup';

export const createSampleSchema = {
  initialValues: {
    customer: '',
    reference: '',
    testGroup: '',
    foodadditive: '',
    dueDate: moment().add(7, 'day').toDate(),
    labDueDate: moment().add(6, 'day').toDate(),
    collectionDate: moment().toDate(),
    mfgDate: moment().toDate(),
    expDate: moment().toDate(),
    analysisDate: undefined,
    completeDate: undefined,
    expDateForFoodSample: undefined,
    locationOfAnalysis: '',
    opinion: '',
    reportDate: undefined,
    refNo: '',
    sampleLocation: '',
    sourceOfWater: '',
    statement: '',
    name: '',
    otherInformation: '',
    sampleReceivedMode: '',
    genericName: '',
    sampleCode: '',
    brandName: '',
    manufacture: '',
    marking: '',
    supplier: '',
    batchNo: '',
    batchSize: '',
    drugLicNo: '',
    type: '',
    description: '',
    samplePacking: '',
    sampleQty: '',
    sampleCondition: '',
    envCondition: '',
    servingSize: '',
    samplingMethod: '',
    hod: '',
    collectionBy: '',
    parameters: [],
    discount: 0,
    gst: 0,
    igst: false,
    paidAmount: 0,
    paymentDueDate: moment().add(7, 'day').toDate(),
    rate: 0,
    sac: '998346',
    additionalFields: {},
    reviewedBy: '',
    authorizedSignature1: '',
    authorizedSignature2: '',
    //!Additional fields
    regulationNo: '',
    beNo: '',
    sealOnContainer: '',
    dateOfDispatch: moment().toDate(),
    testMemoAndDate: '',
    sampleDescription: '',
    physicalAppearance: '',
    label: '',
    fassiLogoAndLicenseNo: '',
    listOfIngratiation: '',
    nutritionalInformation: '',
    vagNonVegDetails: '',
    nameAndAddressOfImporter: '',
    countryOfOrigin: '',
    instructionForUsed: '',
    testMethod: '',
    nameOfPlantSection: '',
    purposeOfMonitoring: '',
    emissionSourceMonitor: '',
    productManufacturedSamplingTime: '',
    nameOfAuthorizedPerson: '',
    controlMeasures: '',
    authorizedPersonAddress: '',
    recoveryOfMaterial: '',
    sealOnContainerBISSeal: '',
    generalSensory: '',
    CHSRegnNo: '',
    fugitiveEmission: '',
    CHACardHolder: '',
    stackTemp: '',
    dateAndPlaceOfCollectionOfSample: '',
    ambientTemperature: '',
    srNo: '',
    stackVelocity: '',
    sealsFixedNo: '',
    conformsOrNon: '',
    sealedCoverByThe: '',
    serviceMode: '',
    foundTheSampleToBe: '',
    sampleStatus: '',
    parametersMonitors: '',
    statusReasons: '',
    remark1: '',
    formType: '',
    specialInstructions: '',
    testAsPer: '',
    labelClaim: '',
  },
  validationSchema: Yup.object().shape({
    customer: Yup.string().required('Customer name is required'),
    testGroup: Yup.string().required('Test Group name is required'),
    reference: Yup.string().optional(),
    genericName: Yup.string().required('Generic name is required'),
    name: Yup.string().required('Sample name is required'),
    supplier: Yup.string().optional(),
    authorizedSignature1: Yup.string().required('Authorized Signature is required'),
    dueDate: Yup.date().optional(),
    labDueDate: Yup.date().required('Lab due date is required'),
    collectionDate: Yup.date().optional(),
    mfgDate: Yup.date().required('Mfg date is required'),
    expDate: Yup.date().optional(),
    hod: Yup.string().required('HOD is required'),
    collectionBy: Yup.string().required('Collection by is required'),
    parameters: Yup.array().required('Parameters is required.').min(1, 'Add at lease one parameter to processed'),
    reviewedBy: Yup.string().required('Reviewed By is required'),
    additionalFields: Yup.mixed().optional(),
    authorizedSignature2: Yup.string().optional(),
    discount: Yup.number().optional(),
    gst: Yup.number().optional(),
    foodadditive: Yup.string().optional(),
    sac: Yup.string().optional(),
    igst: Yup.boolean().optional(),
    paidAmount: Yup.number().optional(),
    paymentDueDate: Yup.date().optional(),
    rate: Yup.number().optional(),
    //!Additional fields
    regulationNo: Yup.string().optional(),
    beNo: Yup.string().optional(),
    sealOnContainer: Yup.string().optional(),
    dateOfDispatch: Yup.date().optional(),
    testMemoAndDate: Yup.string().optional(),
    sampleDescription: Yup.string().optional(),
    otherInformation: Yup.string().optional(),
    sampleReceivedMode: Yup.string().optional(),
    physicalAppearance: Yup.string().optional(),
    label: Yup.string().optional(),
    fassiLogoAndLicenseNo: Yup.string().optional(),
    listOfIngratiation: Yup.string().optional(),
    nutritionalInformation: Yup.string().optional(),
    vagNonVegDetails: Yup.string().optional(),
    nameAndAddressOfImporter: Yup.string().optional(),
    countryOfOrigin: Yup.string().optional(),
    instructionForUsed: Yup.string().optional(),
    testMethod: Yup.string().optional(),
    remark1: Yup.string().optional(),
    specialInstructions: Yup.string().optional(),
    testAsPer: Yup.string().optional(),
    labelClaim: Yup.string().optional(),
  }),
};
